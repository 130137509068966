import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateCurrentUser } from 'store/slices/currentUser/currentUserSlice';
import {
  cognitoLogin,
  isMultiUser,
  verifyPalmettoAccount,
} from 'store/slices/cognito/cognitoThunk';
import { changePortalUsername } from 'store/slices/currentUser/currentUserThunk';
import { fetchGroups } from 'store/slices/userManage/userManageThunk';
import { updateUserLogin } from 'store/slices/cognito/cognitoSlice';
import { updateToken } from 'store/slices/auth/authSlice';
import { ITokenState } from 'store/slices/auth/AuthState';
import { selectError, selectLoading } from 'store/slices/cognito/cognito.selector';
import { selectFetching } from 'store/slices/currentUser/currentUser.selector';
import { defaultAttribute } from 'common/static';
import { UserStatus } from 'common/enum';
import useLoginForm, { UserLogin } from './hooks/useLoginForm';
import { LoginProps, urlPathForgot } from './Login.props';
import LoginView from './Login.view';

const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectLoading);
  const isFetching = useAppSelector(selectFetching);
  const errMessage = useAppSelector(selectError);
  const loginForm = useLoginForm();
  const [noSpaceModal, setNoSpaceModal] = useState(false);
  const [multiUserModal, setMultiUserModal] = useState(false);
  const [successChangeModal, setSuccessChangeModal] = useState(false);
  const [userId, setUserId] = useState(0);
  const [isChanging, setIsChanging] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [errorStatusMsg, setErrorStatusMsg] = useState<string | null>(null);
  const isResetPassword = searchParams.get('resetPassword') === 'true';

  const loginSubmit = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementById('login').click();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', loginSubmit, true);
  }, [loginSubmit]);

  useEffect(() => {
    dispatch(fetchGroups());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loginCognitoFunction(data: UserLogin) {
    const maxRetries = 1;
    let retryCount = 0;

    for (;;) {
      try {
        // Your code that may throw an error goes here
        await executeLogin(data);

        // If the function succeeds, break out of the loop
        break;
      } catch (error) {
        console.error(`Attempt ${retryCount + 1} failed: ${JSON.stringify(error)}`);
        retryCount++;
        if (retryCount > maxRetries) {
          // If maximum retries reached, throw the error or handle it accordingly
          throw new Error(`Maximum retries (${maxRetries}) reached. Unable to complete operation.`);
        }
        // Optionally, you can introduce a delay before retrying
        setErrorMsg(null);
        await new Promise((resolve) => setTimeout(resolve, 1000)); // 1 second delay
      }
    }
  }

  const executeLogin = async (data: UserLogin) => {
    dispatch(updateUserLogin({ username: data.username, password: data.password }));
    const loginData = await dispatch(
      cognitoLogin({ username: data.username, password: data.password })
    ).unwrap();

    if (loginData) {
      if (loginData?.accountInfo) {
        let userInfo = loginData?.accountInfo;
        if (userInfo?.status === UserStatus.PENDING) {
          return setErrorStatusMsg(
            'Your account is pending approval. Please contact your administrator.'
          );
        }
        if (userInfo?.status === UserStatus.NOT_APPROVED) {
          return setErrorStatusMsg(
            'Your account is not approved. Please contact your administrator.'
          );
        }
        if (userInfo?.status === UserStatus.SUSPENDED) {
          return setErrorStatusMsg('Your account is suspended. Please contact your administrator.');
        }
        if (userInfo?.status === UserStatus.APPROVED) {
          if (
            !Object.prototype.hasOwnProperty.call(userInfo, 'attributes') ||
            userInfo?.attributes === null
          ) {
            userInfo = { ...userInfo, attributes: defaultAttribute };
          }
          const payload: ITokenState = {
            authorization: userInfo.accessToken,
            jwToken: loginData.jwtToken,
            email: userInfo.email,
            createdAt: 0,
            ttl: 0,
            user: userInfo.username,
          };
          dispatch(updateToken(payload));
          if (userInfo?.id) {
            window.removeEventListener('keydown', loginSubmit, true);
            dispatch(updateCurrentUser(userInfo));
            navigate('/dashboard');
          }
        }
      }
    }
  };

  async function CheckMultiUser(id: number) {
    const userInfo = await dispatch(isMultiUser(id))
      .unwrap()
      .catch(() => {
        console.log('Failed isMultiUser');
      });

    if (userInfo && userInfo.pvMultipleUser === 1) {
      setErrorMsg('Account is identified as Multi-User');
      return 1;
    }

    if (userInfo && userInfo.pvMultipleUser === 0) {
      return 0;
    }
  }

  const onSubmit: SubmitHandler<UserLogin> = async (data) => {
    const userName = data.username.toLowerCase();
    const hasSpaces = /\s/.test(userName);
    if (hasSpaces) {
      // ?: Check cognito login first
      const loginCognitoResult = await dispatch(
        cognitoLogin({ username: data.username, password: data.password })
      )
        .unwrap()
        .catch(async () => {
          // ?: If cognito failed check Palmetto login API
          const isUserVerified = await dispatch(
            verifyPalmettoAccount({ username: data.username, password: data.password })
          )
            .unwrap()
            .catch(() => {
              setErrorMsg('Unauthorized Account');
            });

          if (isUserVerified && isUserVerified.userId) {
            // ?: Check if MultiUser
            const multiUser = await CheckMultiUser(isUserVerified.userId);
            if (multiUser === 1) {
              setErrorMsg(`Please contact your organization's Palmetto Administrator`);
              return setMultiUserModal(true);
            }

            if (multiUser === 0) {
              // ?: Prompt Change Username
              setUserId(isUserVerified.userId);
              setNoSpaceModal(true);
              return loginForm.setValue('hasSpace', true);
            }
          } else {
            setErrorMsg('Unauthorized Account');
          }
        });
      if (loginCognitoResult && loginCognitoResult.accountInfo) {
        // ?: Check if MultiUser
        const userId = loginCognitoResult.accountInfo.id;
        if (userId) {
          const multiUser = await CheckMultiUser(userId);
          if (multiUser === 1) {
            setErrorMsg('Account is identified as Multi-User');
            return setMultiUserModal(true);
          }

          if (multiUser === 0) {
            // ?: Prompt Change Username
            setNoSpaceModal(true);
            return loginForm.setValue('hasSpace', true);
          }
        }
      }
    } else {
      loginForm.setValue('hasSpace', false);
      await loginCognitoFunction(data).catch((e) => {
        console.log('Error', e);
        setErrorMsg('Unauthorized Account');
      });
    }
  };

  async function UpdateUserName(userId: number) {
    const formData = loginForm.getValues();
    setIsProcessing(true);
    const resultChangeUsername = await dispatch(
      changePortalUsername({
        userId,
        newUsername: formData.changeUserName,
      })
    ).unwrap();

    if (resultChangeUsername) {
      return formData;
    } else {
      setIsProcessing(false);
      throw Error('Failed to change portal username');
    }
  }

  const onConfirm = async (confirm: boolean) => {
    if (confirm) {
      try {
        const isFormValid = await loginForm.trigger();
        if (isFormValid && userId !== 0) {
          setIsChanging(true);
          // *: Execute Change username
          await UpdateUserName(userId).then(() => {
            setTimeout(() => {
              setNoSpaceModal((prevState) => !prevState);
              setSuccessChangeModal(true);
            }, 3000);
          });
        }
      } catch (error) {
        console.log('🚀~file:Login.container.tsx:189 ~ error: ', error);
        setIsChanging(false);
        setNoSpaceModal(false);
        setIsProcessing(false);
        setErrorMsg('Encountered System Error. Please try again!');
      }
    } else {
      loginForm.clearErrors();
      loginForm.setValue('changeUserName', '');
      loginForm.setValue('hasSpace', false);
      setNoSpaceModal(false);
    }
  };

  const onSuccessConfirm = () => {
    setSuccessChangeModal((prevState) => !prevState);
    localStorage.clear();
    window.location.href = '/login';
  };

  const navigateTo = (path: urlPathForgot) => {
    navigate(path);
  };

  const combineProps: LoginProps = {
    errorStatusMsg,
    isResetPassword,
    noSpaceModal,
    successChangeModal,
    inProcess: isLoading || isFetching || isProcessing,
    isChanging: isChanging || isProcessing,
    errMessage: errorMsg || errMessage,
    loginForm,
    onSubmit,
    onConfirm,
    multiUserModal,
    onMultiUserConfirm: () => setMultiUserModal((prevState) => !prevState),
    onSuccessConfirm,
    navigateTo,
  };

  return <LoginView {...combineProps} />;
};

export default Login;
