import { IPalmettoColumns } from './interfaces';
import { PhoneNumberFormat } from '../utils';

const UsersTableColumns: IPalmettoColumns[] = [
  {
    flex: 1,
    field: 'id',
    headerName: 'id',
    numeric: true,
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'name',
    headerName: 'Name',
    numeric: false,
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'groups',
    headerName: 'Groups',
    numeric: false,
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'username',
    headerName: 'Username',
    numeric: false,
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'email',
    headerName: 'Email',
    numeric: false,
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pv_MobilePhone',
    headerName: 'Phone',
    numeric: false,
    enabled: true,
    minWidth: 200,
    renderCell: (row) => PhoneNumberFormat(row?.value),
  },
  {
    flex: 1,
    field: 'administrator',
    headerName: 'Administrator',
    numeric: false,
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'status',
    headerName: 'Status',
    numeric: false,
    enabled: true,
    minWidth: 200,
  },
];

export default UsersTableColumns;
