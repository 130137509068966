import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectTabName } from 'store/slices/menu/menu.selector';
import userManageSelect from 'store/slices/userManage/userManage.selector';
import {
  selectAccessPermission,
  selectUserAccount,
} from 'store/slices/currentUser/currentUser.selector';
import { selectActiveGroupId } from 'store/slices/cognito/cognito.selector';
import { updateUserPermission } from 'store/slices/userManage/userManageSlice';
import { updateUser } from 'store/slices/currentUser/currentUserThunk';
import { fetchSelectedUser } from 'store/slices/userManage/userManageThunk';
import { generateDataPermissions } from 'common/utils';
import { UserStatus } from 'common/enum';
import { IPermissionList } from 'interfaces/persmissionlist.interface';
import { IUserUpdatePayload } from 'interfaces/userinfo.interface';
import UserViewContents from './UserView.view';
import { ActionType, UserViewProps } from './UserView.props';

const UserView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const tabName = useAppSelector(selectTabName);
  const isFetching = useAppSelector(userManageSelect.isLoading);
  const userAccount = useAppSelector(userManageSelect.selectedUser);
  const groupLists = useAppSelector(userManageSelect.selectGroupLists);
  const userPermission = useAppSelector(selectAccessPermission);
  const currentUserInfo = useAppSelector(selectUserAccount);
  const loggedInUserGroupInfo = (currentUserInfo && currentUserInfo?.currentGroups) || [];
  const userActiveGroupID = useAppSelector(selectActiveGroupId);
  const userId = Number(params?.id) || 0;
  const userHasPosition =
    (userAccount &&
      Array.isArray(userAccount?.account2position) &&
      userAccount?.account2position.length > 0) ||
    false;
  const currentUserGroupInfo = userAccount?.currentGroups || [];
  const [denyProgress, setDenyProgress] = useState(false);
  const [approveProgress, setApproveProgress] = useState(false);
  const [isAccountGroupAdmin, setIsAccountGroupAdmin] = useState(false);

  useEffect(() => {
    if (userAccount && userActiveGroupID) {
      const hasGroupAdmin = currentUserGroupInfo.some((obj) => obj.a === 1);
      setIsAccountGroupAdmin(hasGroupAdmin);
      const dataPermissions: IPermissionList[] = generateDataPermissions(
        userAccount,
        userPermission,
        userActiveGroupID,
        groupLists,
        currentUserGroupInfo,
        loggedInUserGroupInfo
      );

      if (dataPermissions.length) {
        dispatch(updateUserPermission(dataPermissions));
      } else {
        dispatch(updateUserPermission([]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccount, userActiveGroupID]);

  const handleDenyApprove = async (action: ActionType) => {
    try {
      if (userAccount) {
        const payload: IUserUpdatePayload = {
          id: userAccount.id || 0,
          pvPersonGivenName: userAccount.pvPersonGivenName || '',
          pvPersonSurName: userAccount.pvPersonSurName || '',
          username: userAccount.username || '',
          email: userAccount.email || '',
          pvOfficePhone: userAccount.pvOfficePhone || '',
          pvMobilePhone: userAccount.pvMobilePhone || '',
          pvOrganizationUnitName: userAccount.pvOrganizationUnitName || '',
          subscribeToEmail: userAccount.subscribeToEmail ? 1 : 0,
          status: action === 'deny' ? UserStatus.NOT_APPROVED : UserStatus.APPROVED,
          pvAdministrator: userAccount.pvAdministrator || 0,
          pvMultipleUser: userAccount.pvMultipleUser || 0,
          pvTrainingAccount: userAccount.pvTrainingAccount ? 1 : 0,
          password: null,
        };

        if (action === 'deny') {
          setDenyProgress(true);
          await executeRequest(payload).finally(() => setDenyProgress(false));
        }

        if (action === 'approve') {
          setApproveProgress(true);
          await executeRequest(payload).finally(() => setApproveProgress(false));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function executeRequest(payload: IUserUpdatePayload) {
    await dispatch(updateUser(payload))
      .unwrap()
      .then(async () => {
        await refreshUserInfo(payload.id);
        return true;
      })
      .catch((e) => {
        console.log('error message >>>', e);
        return false;
      });
  }

  async function refreshUserInfo(userId: number) {
    await dispatch(fetchSelectedUser(Number(userId)))
      .unwrap()
      .then((resp) => {
        if (resp && resp?.account2position) {
          const dataPermissions: IPermissionList[] = generateDataPermissions(
            resp,
            userPermission,
            userActiveGroupID,
            groupLists,
            currentUserGroupInfo,
            loggedInUserGroupInfo
          );
          if (dataPermissions.length) {
            dispatch(updateUserPermission(dataPermissions));
          }
        }
      })
      .catch((err) => {
        console.log('err >>>', err);
        throw err;
      });
  }

  const navigateTo = (path: string) => {
    if (path) {
      navigate(path);
    }
  };

  const combineProps: UserViewProps = {
    userId,
    userHasPosition,
    denyProgress,
    approveProgress,
    isFetching,
    userAccount,
    tabName,
    isAccountGroupAdmin,
    accessPermission: userPermission,
    navigateTo,
    handleDenyApprove,
  };
  return <UserViewContents {...combineProps} />;
};

export default UserView;
